import 'alpinejs'
import focusTrap from 'focus-trap';
import Swiper from 'swiper';
import { slideDown, slideUp } from 'slide-anim';

// window.slideUp = slideUp;
// window.slideDown = slideDown;
window.updateFocusTrap = updateFocusTrap;
window.accordion = accordion;

// focus trap
let modal = document.querySelector('.modal');
let trap = focusTrap(modal);

function updateFocusTrap(state) {
    if(state == true) {
        setTimeout(function() {
            trap.activate();
            modal.querySelector('input').focus();
        }, 100);
    } else {
        trap.deactivate();
    }
}




// accordion
function accordion(slug, open) {
    return {
        slug: slug,
        open: open,

        updateFromHash() {
            // closing other sections causes scrolling issues particularly on mobile
            // if this is required, we need a JS scrolling issue 
            // this.open = (location.hash == `#${this.slug}`) ? true : false;

            if((location.hash == `#${this.slug}`)) this.open = true;
        },

        watch(element) {
            this.open ? slideDown(element) : slideUp(element)
        }
    }
}






// swiper
const swipers = document.querySelectorAll('.swiper-container');
swipers.forEach(element => {
    let opts = {loop: true};
    
    if (element.dataset.swiperSpeed) opts.speed = parseInt(element.dataset.swiperSpeed);
    if (element.dataset.swiperLazy) opts.lazy = true;
    if (element.dataset.swiperDirection) opts.direction = element.dataset.swiperDirection;
    if (element.dataset.swiperEffect) opts.effect = element.dataset.swiperEffect;

    if (element.hasAttribute('data-swiper-has-nav')) {
        let blockID = element.dataset.swiperHasNav;

        opts.navigation = {
            nextEl: '.swiper-button-next' + (blockID ? `[data-nav-for="${blockID}"]` : ''),
            prevEl: '.swiper-button-prev' + (blockID ? `[data-nav-for="${blockID}"]` : ''),
        };
    }


    if (element.hasAttribute('data-swiper-auto')) {
        // if no value set, true = default
        opts.autoplay = (element.dataset.swiperAuto) ? {delay: element.dataset.swiperAuto} : true;
    }
    

    if (element.dataset.swiperPagination) {

        if(element.dataset.swiperPagination == 'vertical') {
            opts.pagination = {
                el: '.swiper-pagination-vertical',
                type: 'bullets',
                clickable: true
            };
        } else {
            opts.pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            };
        }
    }


    new Swiper(element, opts);  
});





const LIs = document.querySelectorAll('li .has-text-primary');
LIs.forEach(element => {
    element.closest('li').classList.add('has-text-primary')
});